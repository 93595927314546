import { IController, IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

export class WheelController implements IController {
    private readonly store: IPrivateBankingStore;

    constructor(store: IPrivateBankingStore) {
        this.store = store;
    }

    public addListeners = () => {
        if (typeof window !== 'undefined') {
            document.addEventListener('scroll', this.onScroll);
            document.addEventListener('wheel', this.onWheel, { passive: true });
        }
    };

    public removeListeners = () => {
        if (typeof window !== 'undefined') {
            document.removeEventListener('scroll', this.onScroll);
            document.removeEventListener('wheel', this.onWheel);
        }
    };

    private onWheel = (event: WheelEvent) => {
        const { step } = this.store.player;

        const currentStep = event.deltaY < 120 ? step - 400 : step;

        const toSlide =
            event.deltaY > 0
                ? this.store.slides[this.store.player.currentIndex + 1] ||
                  this.store.slides[this.store.slides.length - 1]
                : this.store.slides[this.store.player.currentIndex - 1] || this.store.slides[0];

        const to = toSlide.scrollTo;

        const duration = this.store.player.currentIndex === this.store.slides.length ? 2000 : 1500;

        this.store.player.scrollTo(to, duration);
    };

    private onScroll = (event: Event) => {
        event.preventDefault();
    };
}
