import { makeAutoObservable } from 'mobx';

import { SLIDES_SETTINGS } from '@/components/blocks/PrivateBanking/settings';
import { KeysController } from '@/components/blocks/PrivateBanking/Stores/controllers/Keys';
import { TouchController } from '@/components/blocks/PrivateBanking/Stores/controllers/Touch';
import { WheelController } from '@/components/blocks/PrivateBanking/Stores/controllers/Wheel';
import { FramesStore } from '@/components/blocks/PrivateBanking/Stores/FramesStore';
import { Player } from '@/components/blocks/PrivateBanking/Stores/Player';
import { Progress } from '@/components/blocks/PrivateBanking/Stores/Progress';
import { CurrentView, IController, IPlayer, IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

class PrivateBankingStore implements IPrivateBankingStore {
    view: CurrentView = 'animation';

    assetsLoaded = false;

    framesStore = new FramesStore();

    player: IPlayer = new Player(this);

    progress = new Progress(this);

    controllers: IController[] = [new WheelController(this), new TouchController(this), new KeysController(this)];

    constructor() {
        makeAutoObservable(this);
    }

    get slides() {
        return SLIDES_SETTINGS;
    }

    onInit = (totalFrames: number) => {
        this.framesStore.initialize(totalFrames);
    };

    setView = (view: CurrentView) => {
        if (!this.player.isEnded) {
            this.toggleListeners(view === 'animation' ? 'on' : 'off');
        }
        this.view = view;
    };

    setAssetsLoaded = (loaded: boolean) => {
        this.assetsLoaded = loaded;

        if (loaded) {
            this.player.playIntro();
        }
    };

    toggleListeners = (state: 'on' | 'off') => {
        if (state === 'on') {
            this.controllers.forEach(controller => controller.addListeners());
        }

        if (state === 'off') {
            this.controllers.forEach(controller => controller.removeListeners());
        }
    };

    replay = () => {
        this.setView('animation');

        window.scrollTo(0, 0);

        this.framesStore.setCurrentFrame(0);

        this.player.setSegmentIndex(0);

        this.player.playIntro();
    };
}

export default new PrivateBankingStore();
