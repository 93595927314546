import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

function Logo() {
    const { replay } = PrivateBankingStore;

    return (
        <div role="presentation" className={clsx(cn.item, cn.itemLogo)} onClick={replay}>
            <div className={cn.logo}>
                <CustomImage
                    src="/img-next/svg/privat-bank/privat-bank-logo.svg"
                    alt="Private Bank"
                    width="67"
                    height="83"
                    loading="eager"
                    priority
                />
            </div>
            <div className={cn.logo}>
                <CustomImage
                    src="/img-next/svg/privat-bank/pb.svg"
                    alt="Private Bank"
                    width="67"
                    height="33"
                    loading="eager"
                />
            </div>
        </div>
    );
}

export default observer(Logo);
