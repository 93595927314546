import { useEffect, useState } from 'react';
import { useMedia } from 'react-use';

import { TABLET_MOBILE } from '@/utils/constants';

import earthMobile from './json/earth-mobile.json';
import skyMobile from './json/sky-mobile.json';

/**
 * Mobile-first загрузка анимации
 */
export const useLazyAnimationData = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [sky, setSun] = useState<object>(null);

    const [earth, setEarth] = useState<object>(null);

    const isDesktop = useMedia(`(min-width: ${TABLET_MOBILE}px)`);

    useEffect(() => {
        if (isDesktop) {
            Promise.all([
                import(/* webpackChunkName: "sky-desktop" */ './json/sky-desktop.json'),
                import(/* webpackChunkName: "earth-desktop" */ './json/earth-desktop.json'),
            ])
                .then(([sunDesktop, earthDesktop]) => {
                    setSun(sunDesktop);
                    setEarth(earthDesktop);
                    setIsLoaded(true);
                })
                .catch(console.error);
        } else {
            setSun(skyMobile);
            setEarth(earthMobile);
            setIsLoaded(true);
        }
    }, [isDesktop]);

    return { sky, earth, isLoaded };
};
