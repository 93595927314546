import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import * as z from 'zod';

import ControlledCitySelect from '@/components/blocks/forms/UI/ControlledCitySelect';
import { BtnPrivate } from '@/components/blocks/PrivateBanking/BtnPrivate';
import Input from '@/components/UI/Input';
import Title from '@/components/UI/Title';
import { InnerFormValues } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IProps {
    handleOpen?: () => void;
    _template?: 'privateBankingForm';
}

const FormPrivate: React.FC<IProps> = ({ handleOpen, _template = 'privateBankingForm' }) => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);

    const [isFail, setIsFail] = useState(false);

    const schema = z
        .object({
            phone: z
                .string()
                .min(1, '*  обязательно для заполнения')
                .refine(value => value && value.indexOf('_') < 0, {
                    message: 'Неверный формат номера телефона',
                }),
            fio: z
                .string()
                .min(3, 'ФИО не должно быть короче 3х символов')
                .max(255, 'ФИО не должно быть больше 255 символов')
                .refine(val => val !== undefined && val !== null, {
                    message: '*  обязательно для заполнения',
                }),
            city: z.string().min(1, '*  обязательно для заполнения'),
        })
        .required();

    const formResolver: Resolver<any> = zodResolver(schema);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm<InnerFormValues>({ resolver: formResolver, mode: 'onBlur' });

    const onSubmit = async finalData => {
        const filteredFinalData = removePropertyFromObject(finalData, 'accept');
        const formData = getFormDataFromHookForm(filteredFinalData);

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${_template}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setIsFail(true);
                throw new Error(response?.data?.errors[0].message);
            } else {
                setIsSuccess(true);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const [isErrorFio, errorFioText] = getFormErrors(errors, 'fio');
    const [isErrorPhone, errorPhoneText] = getFormErrors(errors, 'phone');
    const [isErrorCity, errorCityText] = getFormErrors(errors, 'city');

    return (
        <div className={cn.wrapper}>
            {!isFail && !isSuccess && (
                <div className={cn.form}>
                    <div className={cn.title}>
                        Почта Банк <br />
                        Private Banking
                    </div>
                    <form className={cn.formWrap} onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
                        <div className={clsx(cn.input)}>
                            <Input
                                type="tel"
                                label="Мобильный телефон*"
                                mask="+7 (999) 999-99-99"
                                autoComplete="off"
                                aria-autocomplete="none"
                                isPrivateBank
                                isError={isErrorPhone}
                                errorText={errorPhoneText}
                                {...register('phone')}
                            />
                        </div>
                        <div className={cn.input}>
                            <Input
                                type="text"
                                label="ФИО*"
                                autoComplete="off"
                                aria-autocomplete="none"
                                isPrivateBank
                                isError={isErrorFio}
                                errorText={errorFioText}
                                {...register('fio')}
                            />
                        </div>

                        <div className={cn.input}>
                            <ControlledCitySelect
                                control={control}
                                placeholder="Ваш город*"
                                shouldColored
                                isPrivateBank
                                isError={isErrorCity}
                                errorText={errorCityText}
                            />
                        </div>
                        <div className={cn.formText}>
                            Нажимая кнопку «Оставить заявку»,
                            <br />я принимаю
                            <a onClick={handleOpen} className={cn.formLink} role="presentation">
                                Условия обработки персональных данных
                            </a>
                        </div>
                        <div className={cn.btn}>
                            <BtnPrivate buttonType="submit" type="button" label="Оставить заявку" isBig />
                        </div>
                    </form>
                </div>
            )}

            {isFail && (
                <Title
                    title="К сожалению, произошла ошибка при отправке формы. Попробуйте позднее."
                    level={2}
                    className={cn.title}
                />
            )}

            {isSuccess && (
                <div className={cn.success}>
                    <div className={clsx(cn.title, cn.titleSuccess)}>Спасибо!</div>
                    <div className={cn.text}>Скоро с вами свяжется наш менеджер.</div>
                </div>
            )}
        </div>
    );
};

export default FormPrivate;
