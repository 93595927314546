import { v4 } from 'uuid';

import { SlideConfig } from '@/components/blocks/PrivateBanking/types';

export const RIGHT_POINT = 'calc(100vw - 100% - 60px)';

export const SLIDES_SETTINGS: SlideConfig[] = [
    {
        id: v4(),
        range: [0, 175],
        speed: 1,
        text: `Откройте новые горизонты \nдля своего капитала`,
        background: 'linear-gradient(94.83deg, #191925 -24.51%, #A47B70 54.83%)',
        transition: {
            duration: 2,
        },
        variants: {
            open: {
                opacity: [0, 1],
                y: [200, 30],
                rotate: ['8deg', '0deg'],
            },
            end: {
                y: 0,
                opacity: 0,
                rotate: 0,
            },
        },
        scrollTo: 5500,
    },
    {
        id: v4(),
        range: [177, 210],
        speed: 1,
        text: `<strong>Почта Банк<br> Private Banking</strong> \n создаёт для вас доступное пространство премиальных сервисов и привилегий`,
        background: 'linear-gradient(94.3deg, #FFC88F -10.02%, #FFFFFF 105.94%)',
        transition: {
            duration: 2,
        },
        variants: {
            open: {
                opacity: [0, 1],
                y: [50, 0],
            },
            end: {
                y: 0,
                opacity: 0,
            },
        },
        scrollTo: 6000,
    },
    {
        id: v4(),
        range: [220, 270],
        speed: 1,
        text: `Комфортный<br> порог входа`,
        background: 'linear-gradient(89.08deg, #FFC4C4 2.32%, #FFFFFF 94.22%)',
        transition: {
            duration: 1,
        },
        variants: {
            open: {
                opacity: [0, 1],
            },
            end: {
                opacity: 0,
            },
        },
        scrollTo: 8500,
    },
    {
        id: v4(),
        range: [270, 310],
        speed: 1,
        text: `Новые тренды \n в управлении активами`,
        background: 'linear-gradient(85.27deg, #24140B -7.17%, #D35900 56.21%)',
        transition: {
            duration: 2,
        },
        variants: {
            open: {
                opacity: [0, 1],
            },
            end: {
                opacity: 0,
            },
        },
        scrollTo: 9700,
    },
    {
        id: v4(),
        range: [310, 350],
        speed: 1,
        text: `Цифровая платформа \n для инвестиционных \n стратегий`,
        background: 'linear-gradient(85.27deg, #D35900 -5.85%, #24140B 51.6%)',
        transition: {
            duration: 2,
        },
        variants: {
            initial: {
                x: RIGHT_POINT,
            },
            open: {
                opacity: [0, 1],
                x: RIGHT_POINT,
            },
            end: {
                opacity: 0,
                x: RIGHT_POINT,
            },
        },
        scrollTo: 11200,
    },
    {
        id: v4(),
        range: [350, 500],
        speed: 2,
        text: `Персональная \n команда консультантов`,
        background: 'linear-gradient(257.09deg, #FFFFFF 6.05%, #F38D7C 150.68%), #1B1C20',
        transition: {
            duration: 2,
        },
        variants: {
            initial: {
                x: RIGHT_POINT,
            },
            open: {
                opacity: [0, 1],
                x: RIGHT_POINT,
            },
            end: {
                opacity: 0,
                x: RIGHT_POINT,
            },
        },
        scrollTo: 14250,
    },
    {
        id: v4(),
        range: [500, 600],
        speed: 1,
        scrollTo: 20000,
    },
];
