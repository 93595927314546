import { IController, IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

export class KeysController implements IController {
    private readonly store: IPrivateBankingStore;

    constructor(store: IPrivateBankingStore) {
        this.store = store;
    }

    public addListeners = () => {
        if (typeof window !== 'undefined') {
            document.addEventListener('keydown', this.onKeyDown);
        }
    };

    public removeListeners = () => {
        if (typeof window !== 'undefined') {
            document.removeEventListener('keydown', this.onKeyDown);
        }
    };

    private onKeyDown = (event: KeyboardEvent) => {
        const isArrowUp = event.key === 'ArrowUp';
        const isArrowDown = event.key === 'ArrowDown';

        if (!isArrowUp && !isArrowDown) return;

        const step = this.store.player.step + 300;

        const to = isArrowDown ? window.scrollY + step : window.scrollY - step;

        this.store.player.scrollTo(to);
    };
}
