import { makeAutoObservable } from 'mobx';

import { IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

export class Progress {
    circlePositions: number[] = [];

    readonly store: IPrivateBankingStore;

    constructor(store: IPrivateBankingStore) {
        this.store = store;

        this.setCirclePositions();

        makeAutoObservable(this);
    }

    get lineScaleSize(): number {
        const index = this.store.slides?.findIndex(
            item =>
                item.range[0] <= +this.store.framesStore.numericFrame &&
                item.range[1] > +this.store.framesStore.numericFrame
        );

        if (index === 0 || index < 0) {
            return 0;
        }

        if (index === this.store.slides.length) {
            return 1;
        }

        const scale = this.circlePositions[index] / 100;

        return +scale.toFixed(4);
    }

    get lineScale(): number {
        const { currentIndex } = this.store.player;

        if (currentIndex === 0 || currentIndex < 0) {
            return 0;
        }

        if (currentIndex === this.store.slides.length) {
            return 1;
        }

        const scale = this.circlePositions[this.store.player.currentIndex] / 100;

        return +scale.toFixed(4);
    }

    handleTransition = (index: number) => {
        const slide = this.store.slides[index];

        if (!slide?.scrollTo) return;

        const duration = index === this.store.slides.length ? 2000 : 1500;

        this.store.player.scrollTo(slide.scrollTo, duration);
    };

    setCirclePositions = () => {
        const visibleSlidesLength = this.store.slides.length - 1;

        const segmentWidth = 100 / visibleSlidesLength;

        const positions = Array.from({ length: visibleSlidesLength }, (_, i) => +(segmentWidth * i).toFixed(2));

        positions[0] = 0.01;

        positions.push(100);

        this.circlePositions = positions;
    };
}
