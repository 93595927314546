import clsx from 'clsx';
import React, { FC } from 'react';

import cn from './style.module.sass';

interface IProps {
    active?: boolean;
    handleClick?: () => void;
}

const BtnBurger: FC<IProps> = ({ active = false, handleClick }) => (
    <div onClick={handleClick} className={clsx(cn.burger, active && cn.active)} role="presentation">
        <div className={cn.burgerWrap}>
            <span className={clsx(cn.itemBurger, cn.itemBurgerTop)} />
            <span className={clsx(cn.itemBurger, cn.itemBurgerMiddle)} />
            <span className={clsx(cn.itemBurger, cn.itemBurgerBottom)} />
        </div>
    </div>
);

export default BtnBurger;
