import { action, computed, makeObservable, observable } from 'mobx';

/**
 * Кеширует все возможные фреймы при загрузке
 * для передачи в lottie, стабилизирует FPS
 */
export class FramesStore {
    currentFrame = '0.000';

    cache = new Map<number, string>();

    totalFrames: number;

    maxScrollY: number;

    duration = 20;

    constructor() {
        makeObservable(this, {
            currentFrame: observable,
            numericFrame: computed,
            setCurrentFrame: action,
        });
    }

    get numericFrame(): number {
        return Math.floor(+this.currentFrame);
    }

    initialize = (totalFrames: number) => {
        this.totalFrames = totalFrames;

        this.setMaxScrollY();

        this.preload();
    };

    setMaxScrollY() {
        let maxY = 0;

        if (typeof window !== 'undefined') {
            maxY = Math.max(
                document.body.scrollHeight,
                document.body.offsetHeight,
                document.documentElement.clientHeight,
                document.documentElement.scrollHeight,
                document.documentElement.offsetHeight
            );
        }

        this.maxScrollY = maxY;
    }

    calculateFrame(y: number) {
        return (this.totalFrames * (y / (this.duration * 1000))).toFixed(5);
    }

    setCache(y: number) {
        const frame = this.calculateFrame(y);

        this.cache.set(y, frame);

        return frame;
    }

    setCurrentFrame = (frame?: number) => {
        if (typeof window !== 'undefined') {
            this.currentFrame = this.getFrame(frame ?? window.scrollY);
        }
    };

    getFrame(y: number) {
        if (this.cache.has(y)) {
            return this.cache.get(y);
        }

        return this.setCache(y);
    }

    preload() {
        for (let i = 0; i < this.maxScrollY; i += 1) {
            this.setCache(i);
        }
    }
}
