export const tabContent = [
    {
        code: '1',
        title: 'Инвестиционные решения',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '2',
        title: 'Вклады и сбережения',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '3',
        title: 'Банковские карты',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '4',
        title: 'Программа лояльности',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '5',
        title: 'Страховые решения',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '6',
        title: 'Консьерж-сервис',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '7',
        title: 'Нефинансовые услуги',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
    {
        code: '8',
        title: 'Персональный финансовый консультант',
        text: '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой для управления инвестициями и предлагает структурированные продукты с защитой капитала, индивидуальные счета доверительного управления, паевые инвестиционные фонды, еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе, работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего персонального менеджера.</p>',
    },
];
