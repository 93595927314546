import clsx from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';

import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';

import cn from './style.module.sass';

const events = ['mousedown', 'keypress', 'scroll', 'touchstart'];

function ScrollArrow() {
    const [show, setShow] = useState(true);
    const [darkTheme, setDarkTheme] = useState(false);

    const timer = useRef<NodeJS.Timeout>(null);

    const { currentIndex } = PrivateBankingStore.player;

    useEffect(() => {
        const resetTimer = () => {
            setShow(false);

            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setShow(true);
            }, 5000);
        };

        events.forEach(name => {
            document.addEventListener(name, resetTimer, true);
        });
    }, [timer.current]);

    useEffect(() => {
        if (currentIndex) {
            setDarkTheme(currentIndex >= 2 && currentIndex <= 4);
        }
    }, [currentIndex]);

    return (
        <motion.div
            className={clsx(cn.arrow, show && cn.arrowShow)}
            animate={{ y: -20 }}
            initial={{ y: 0 }}
            transition={{
                y: {
                    ease: 'easeInOut',
                    repeat: Infinity,
                    repeatType: 'mirror',
                    duration: 1,
                },
            }}
        >
            {darkTheme && (
                <Image
                    src="/img-next/png/private/caret-dark.svg"
                    className={clsx(cn.arrow, show && cn.arrowShow)}
                    width={32}
                    height={16}
                    alt=""
                />
            )}
            {!darkTheme && (
                <Image
                    src="/img-next/png/private/caret.svg"
                    className={clsx(cn.arrow, show && cn.arrowShow)}
                    width={32}
                    height={16}
                    alt=""
                />
            )}
        </motion.div>
    );
}

export default observer(ScrollArrow);
