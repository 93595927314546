import { AnimationItem } from 'lottie-web';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import Lottie from 'react-lottie-player';

import { useLazyAnimationData } from '@/components/blocks/PrivateBanking/Lottie/index.hooks';
import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';

import cn from './style.module.sass';

const LOTTIE_DEFAULTS = {
    progressiveLoad: false,
    viewBoxOnly: true,
    hideOnTransparent: true,
    preserveAspectRatio: 'xMidYMid slice',
};

function LottieSegments() {
    const { onInit, setAssetsLoaded, framesStore } = PrivateBankingStore;

    const { sky, earth, isLoaded } = useLazyAnimationData();

    const lottieRefEarth = useRef<AnimationItem>(null);

    const lottieRefSky = useRef<AnimationItem>(null);

    useEffect(() => {
        if (!lottieRefEarth.current || !lottieRefSky.current || !isLoaded) {
            return;
        }

        onInit(lottieRefSky.current.totalFrames);

        const onImagesLoaded = () => setAssetsLoaded(true);

        lottieRefEarth.current?.addEventListener('loaded_images', onImagesLoaded);
    }, [isLoaded, framesStore]);

    useEffect(() => {
        if (!lottieRefEarth.current || !lottieRefSky.current || !isLoaded) {
            return;
        }

        lottieRefSky.current.goToAndStop(framesStore.currentFrame, true);
        lottieRefEarth.current.goToAndStop(framesStore.currentFrame, true);
    }, [framesStore.currentFrame]);

    if (!isLoaded) {
        return null;
    }

    return (
        <>
            <Lottie
                // eslint-disable-next-line
                // @ts-expect-error
                ref={lottieRefSky}
                className={cn.sky}
                renderer="svg"
                animationData={sky}
                useSubframes
                rendererSettings={LOTTIE_DEFAULTS}
            />

            <Lottie
                // eslint-disable-next-line
                // @ts-expect-error
                ref={lottieRefEarth}
                renderer="svg"
                className={cn.earth}
                animationData={earth}
                useSubframes
                rendererSettings={LOTTIE_DEFAULTS}
            />
        </>
    );
}

export default observer(LottieSegments);
