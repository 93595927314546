import clsx from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';

import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';

import cn from './style.module.sass';

function AnimatedText() {
    const {
        slides,
        framesStore: { numericFrame },
    } = PrivateBankingStore;

    return (
        <div className={cn.wrap}>
            {slides.map(item => {
                const [from, to] = item.range;

                if (!item.text) return null;

                let animate: string;

                if (numericFrame <= from) {
                    animate = 'initial';
                }

                if (numericFrame >= from && numericFrame <= to) {
                    animate = 'open';
                }

                if (numericFrame > to - 10) {
                    animate = 'end';
                }

                return (
                    <motion.div
                        key={item.id}
                        className={clsx(cn.title, cn.first)}
                        animate={animate}
                        transition={item.transition}
                        variants={item.variants}
                        initial={{ opacity: 0 }}
                        style={{
                            background: item.background,
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                );
            })}
        </div>
    );
}

export default observer(AnimatedText);
