import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import BtnBurger from '@/components/blocks/PrivateBanking/BtnBurger';
import { BtnPrivate } from '@/components/blocks/PrivateBanking/BtnPrivate';
import Logo from '@/components/blocks/PrivateBanking/Logo';
import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';

import MobileMenu from '../MobileMenu';
import cn from './style.module.sass';

type PrivateHeaderProps = {
    setTabFromMenu?: (tabCode: string) => void;
};

const PrivateHeader: React.FC<PrivateHeaderProps> = ({ setTabFromMenu }) => {
    const {
        view,
        setView,
        player: { isEnded },
    } = PrivateBankingStore;

    const { circlePositions, handleTransition } = PrivateBankingStore.progress;

    const [isMenuShown, showMenu] = useState(false);

    const show = () => showMenu(true);

    const hide = () => showMenu(false);

    const isCloseVisible = view === 'tabs' || view === 'personal-info' || (!isEnded && view === 'form');

    const openTabs = () => {
        handleTransition(0);

        setView('tabs');
    };

    const openForm = () => {
        handleTransition(0);

        setView('form');
    };

    const handleClose = () => {
        if (isEnded) {
            setView('form');
        } else {
            handleTransition(0);
            setView('animation');
        }
    };

    return (
        <div className={clsx(cn.header, cn.hidden)}>
            <div className={cn.wrap}>
                <div className={cn.grid}>
                    <Logo />

                    <div className={cn.item}>
                        <div className={cn.button}>
                            {view !== 'tabs' && (
                                <div className={clsx(cn.buttonItem, cn.tabletHidden)}>
                                    <BtnPrivate type="button" label="Узнать больше" onClick={openTabs} />
                                </div>
                            )}
                            {view !== 'form' && (
                                <div className={cn.buttonItem}>
                                    <BtnPrivate type="button" label="Стать клиентом" onClick={openForm} />
                                </div>
                            )}

                            <div className={clsx(cn.buttonItem, cn.buttonItemBurger)}>
                                <BtnBurger active={isMenuShown} handleClick={show} />
                            </div>
                            <div
                                className={clsx(
                                    cn.buttonItem,
                                    cn.buttonItemBurger,
                                    cn.buttonItemBurgerWindow,
                                    isCloseVisible && cn.active
                                )}
                            >
                                <BtnBurger active={isCloseVisible} handleClick={handleClose} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileMenu active={isMenuShown} closeMenu={hide} setTabFromMenu={setTabFromMenu} />
        </div>
    );
};

export default observer(PrivateHeader);
