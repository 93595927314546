import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import AnimatedText from '@/components/blocks/PrivateBanking/AnimatedText';
import FormPrivate from '@/components/blocks/PrivateBanking/FormPrivate';
import Header from '@/components/blocks/PrivateBanking/Header';
import InfoTabs from '@/components/blocks/PrivateBanking/InfoTabs';
import LottieSegments from '@/components/blocks/PrivateBanking/Lottie';
import PersonalData from '@/components/blocks/PrivateBanking/PersonalData';
import Progress from '@/components/blocks/PrivateBanking/Progress';
import Store from '@/components/blocks/PrivateBanking/Stores/Store';
import cn from '@/components/blocks/PrivateBanking/style.module.sass';
import ErrorFallback from '@/components/UI/ErrorFallback';
import Feature from '@/domain/feature/Feature';

export interface IPrivateBanking {
    _template?: 'privateBankingForm';
}

const PrivateBanking: React.FC<IPrivateBanking> = observer(() => {
    const { assetsLoaded, view, setView } = Store;

    const [currentTab, setTab] = useState<string>('1');

    const ref = useRef(null);

    const setTabFromMenu = (tabCode: string) => {
        setView('tabs');
        setTab(tabCode);
    };

    useEffect(() => {
        window.history.scrollRestoration = 'manual';
    }, []);

    if (!Feature.isFeatureEnabled('enablePrivateBank')) {
        return null;
    }

    return (
        <div ref={ref} className={clsx(cn.layout, view !== 'animation' && cn.fixedLayout)}>
            <div className={clsx(view === 'animation' && cn.fixedContainer)}>
                <Header setTabFromMenu={setTabFromMenu} />

                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <div className={cn.content}>
                        <div className={clsx(cn.platform, view !== 'animation' && cn.fixed)}>
                            <Image src="/img-next/png/private/private-bg.png" alt="" className={cn.bg} fill />
                            <LottieSegments />
                        </div>

                        {view === 'animation' && (
                            <>
                                {assetsLoaded && <AnimatedText />}
                                <Progress />
                            </>
                        )}
                        {view === 'form' && <FormPrivate handleOpen={() => setView('personal-info')} />}
                        {view === 'personal-info' && <PersonalData />}
                        {view === 'tabs' && <InfoTabs currentTab={currentTab} setTab={setTab} />}
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    );
});

export default PrivateBanking;
