import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

export interface ButtonProps {
    /**
     * Тип: кнопка или ссылка
     */
    type: 'button' | 'link';
    /**
     * Ссылка
     */
    href?: string;
    /**
     * Target атрибут
     */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Большая кнопка
     */
    isBig?: boolean;
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Тип, в случае выбора кнопки
     */
    buttonType?: 'button' | 'submit' | 'reset';
}

export const BtnPrivate: React.FC<ButtonProps> = ({
    label,
    disable = false,
    type = 'button',
    href,
    target,
    onClick,
    isBig = false,
    buttonType = 'button',
}) => {
    const className = clsx(cn.btn, disable === true && cn.disable, isBig === true && cn.big);

    const children = <span>{label}</span>;

    return (
        <div>
            {type === 'button' ? (
                // eslint-disable-next-line react/button-has-type
                <button type={buttonType} className={className} onClick={onClick}>
                    {children}
                </button>
            ) : (
                <a href={href} target={target} className={className}>
                    {children}
                </a>
            )}
        </div>
    );
};
