import * as Sentry from '@sentry/nextjs';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    error: any;
}

function ErrorFallback({ error }: IProps) {
    Sentry.withScope(scope => {
        scope.setLevel('error');

        // The exception has the event level set by the scope (info).
        Sentry.captureMessage(`Некорректные данные для блока`);
        Sentry.captureException(new Error(error));
    });

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                <div className={cn.mainTitle}>Что-то пошло не так, но мы уже знаем о проблеме и занимаемся ею.</div>
            </div>
        </div>
    );
}

export default ErrorFallback;
