import clsx from 'clsx';
import React from 'react';

import { tabContent } from '@/components/blocks/PrivateBanking/InfoTabs/content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import CustomSelect from '@/components/UI/CustomSelect';
import { useResize } from '@/hooks/useResize';
import { TABLET_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

export interface IProps {
    currentTab?: string;
    setTab?: (tabCode: string) => void;
}

const InfoTabs: React.FC<IProps> = ({ setTab, currentTab }) => {
    const width = useResize();

    const isDesktop = width > TABLET_WIDTH;

    const currentTabContent = tabContent.find(item => item.code === currentTab);

    return (
        <div className={clsx(cn.wrap, cn.active)}>
            <div className={cn.grid}>
                {isDesktop ? (
                    <div className={clsx(cn.item, cn.itemTitle)}>
                        {tabContent?.map(({ title, code }) => (
                            <div
                                key={code}
                                className={clsx(currentTab === code && cn.selected, cn.tab)}
                                onClick={() => setTab(code)}
                                role="presentation"
                            >
                                {title}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className={clsx(cn.item, cn.itemTitle)}>
                        <CustomSelect
                            name="info"
                            isSearchable={false}
                            onChange={option => {
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-argument
                                setTab(option.value);
                            }}
                            options={tabContent.map(item => ({
                                label: item.title,
                                value: item.code,
                            }))}
                            value={{
                                label: currentTabContent.title,
                                value: currentTabContent.code,
                            }}
                            isPrivateBank
                            isPrivateBankTabs
                        />
                    </div>
                )}

                <div className={clsx(cn.item, cn.itemText)}>
                    <div className={cn.title}>{currentTabContent.title}</div>
                    <div className={cn.text} dangerouslySetInnerHTML={{ __html: currentTabContent.text }} />
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(InfoTabs);
