import clsx from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React from 'react';

import ScrollArrow from '@/components/blocks/PrivateBanking/ScrollArrow';
import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';

import cn from './style.module.sass';

function Progress() {
    const { isEnded } = PrivateBankingStore.player;
    const { circlePositions, lineScale, handleTransition, lineScaleSize } = PrivateBankingStore.progress;

    const handleCircleClick = (index: number) => () => {
        handleTransition(index);
    };

    return (
        <div className={clsx(cn.bottom, isEnded && cn.fade)}>
            <ScrollArrow />

            <div className={clsx(cn.bottomItem, cn.bottomItemText)}>Почта Банк Private Banking</div>

            <div className={clsx(cn.bottomItem, cn.bottomItemProgress)}>
                <div className={cn.bottomItemWrap}>
                    <motion.div
                        className={cn.progress}
                        animate={{ scaleX: lineScaleSize }}
                        transition={{ ease: 'linear', duration: 1, delay: 0 }}
                    />
                    <div className={cn.circlesStraight}>
                        {circlePositions.map(
                            (left, index) =>
                                !!left && (
                                    <div
                                        key={left}
                                        className={cn.circleArea}
                                        style={{ left: `${left}%` }}
                                        onClick={handleCircleClick(index)}
                                        role="presentation"
                                    >
                                        <div className={cn.circle} />
                                    </div>
                                )
                        )}
                    </div>
                </div>
            </div>

            <div className={clsx(cn.bottomItem, cn.bottomItemText)}>Новые горизонты возможного</div>
        </div>
    );
}

export default observer(Progress);
