import clsx from 'clsx';
import React from 'react';

import BtnBurger from '@/components/blocks/PrivateBanking/BtnBurger';
import { BtnPrivate } from '@/components/blocks/PrivateBanking/BtnPrivate';
import { tabContent } from '@/components/blocks/PrivateBanking/InfoTabs/content';
import PrivateBankingStore from '@/components/blocks/PrivateBanking/Stores/Store';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

export type MobileMenuProps = {
    closeMenu: () => void;
    active?: boolean;
    setTabFromMenu?: (tabCode: string) => void;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ closeMenu, active, setTabFromMenu }) => {
    const { setView } = PrivateBankingStore;

    return (
        <div className={clsx(cn.menu, active && cn.active)}>
            <div className={cn.top}>
                <div className={cn.item}>
                    <div className={cn.logo}>
                        <CustomImage
                            src="/img-next/svg/privat-bank/privat-bank-logo.svg"
                            alt="Private Bank"
                            width="67"
                            height="83"
                        />
                    </div>
                    <div className={cn.logo}>
                        <CustomImage src="/img-next/svg/privat-bank/pb.svg" alt="Private Bank" width="67" height="33" />
                    </div>
                </div>
                <div className={cn.item}>
                    <BtnBurger active={active} handleClick={closeMenu} />
                </div>
            </div>
            <div className={cn.list}>
                {tabContent?.map(({ title, code }) => (
                    <div
                        key={code}
                        className={clsx(cn.link)}
                        role="presentation"
                        onClick={() => {
                            closeMenu();
                            setTabFromMenu(code);
                        }}
                    >
                        {title}
                    </div>
                ))}
            </div>
            <div className={cn.bottom}>
                <BtnPrivate
                    buttonType="button"
                    type="button"
                    label="Оставить заявку"
                    onClick={() => {
                        closeMenu();
                        setView('form');
                    }}
                />
            </div>
        </div>
    );
};

export default MobileMenu;
