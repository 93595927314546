import { IController, IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

export class TouchController implements IController {
    private readonly store: IPrivateBankingStore;

    // верхняя граница, c которой нужно учитывать touch события
    private yTopBound = 100;

    private threshold = 100;

    private timeout = 500;

    private xDown: number = null;

    private yDown: number = null;

    private xDiff: number = null;

    private yDiff: number = null;

    private timeDown: number = null;

    private startEl = null;

    constructor(store: IPrivateBankingStore) {
        this.store = store;
    }

    public addListeners = () => {
        if (typeof window !== 'undefined') {
            document.addEventListener('touchstart', this.handleTouchStart, false);
            document.addEventListener('touchmove', this.handleTouchMove, false);
            document.addEventListener('touchend', this.handleTouchEnd, false);
        }
    };

    public removeListeners = () => {
        if (typeof window !== 'undefined') {
            document.removeEventListener('touchstart', this.handleTouchStart);
            document.removeEventListener('touchmove', this.handleTouchMove);
            document.removeEventListener('touchend', this.handleTouchEnd);
        }
    };

    private handleTouchStart = (event: TouchEvent) => {
        this.startEl = event.target;
        this.timeDown = Date.now();
        this.xDown = event.touches[0].clientX;
        this.yDown = event.touches[0].clientY;
        this.xDiff = 0;
        this.yDiff = 0;
    };

    private handleTouchMove = (event: TouchEvent) => {
        if (!this.xDown || !this.yDown) return;

        this.xDiff = this.xDown - event.touches[0].clientX;
        this.yDiff = this.yDown - event.touches[0].clientY;
    };

    private handleTouchEnd = (event: TouchEvent) => {
        if (this.startEl !== event.target) {
            return;
        }

        if (this.yDown <= this.yTopBound) {
            return;
        }

        const timeDiff = Date.now() - this.timeDown;

        let direction: 1 | -1;

        if (Math.abs(this.yDiff) > this.threshold && timeDiff < this.timeout) {
            if (this.yDiff > 0) {
                // swiped-up
                direction = 1;
            } else {
                // swiped-down
                direction = -1;
            }

            // const step = this.store.player.step + 300;

            const toSlide =
                direction > 0
                    ? this.store.slides[this.store.player.currentIndex + 1] ||
                      this.store.slides[this.store.slides.length - 1]
                    : this.store.slides[this.store.player.currentIndex - 1] || this.store.slides[0];

            const step = toSlide.scrollTo;

            const to = step;

            const duration = this.store.player.currentIndex === this.store.slides.length ? 2000 : 1500;

            this.store.player.scrollTo(to, duration);
        }

        this.xDown = null;
        this.yDown = null;
        this.timeDown = null;
    };
}
