import { AnimationSegment } from 'lottie-web';
import { makeAutoObservable, reaction } from 'mobx';

import { IPlayer, IPrivateBankingStore } from '@/components/blocks/PrivateBanking/types';

export class Player implements IPlayer {
    introWasPlayed = false;

    introPosition = 5500;

    direction: 1 | -1 = 1;

    segmentIndex = 0;

    step = 750;

    duration = 500;

    readonly store: IPrivateBankingStore;

    constructor(store: IPrivateBankingStore) {
        this.store = store;

        reaction(
            () => this.currentIndex,
            currentIndex => {
                if (currentIndex === 1) {
                    this.store.toggleListeners('on');
                }

                if (this.isEnded) {
                    this.store.toggleListeners('off');

                    setTimeout(() => {
                        this.store.setView('form');
                    }, 1000);
                }
            },
            {
                fireImmediately: true,
                delay: 1000,
            }
        );

        makeAutoObservable(this, {
            introWasPlayed: false,
        });
    }

    get currentIndex() {
        const {
            framesStore: { numericFrame },
            slides,
        } = this.store;

        if (!numericFrame) return 0;

        if (numericFrame > slides[slides.length - 1].range[1]) {
            return slides.length - 1;
        }

        return slides.findIndex(item => numericFrame >= item.range[0] && numericFrame <= item.range[1]);
    }

    get isEnded() {
        return this.currentIndex === this.store.slides.length - 1;
    }

    get range(): AnimationSegment {
        const segment = this.store.slides[this.currentIndex];

        return this.direction > 0 ? segment.range : [segment.range[1], segment.range[0]];
    }

    get speed(): number {
        return this.store.slides[this.currentIndex].speed;
    }

    playIntro = () => {
        this.introWasPlayed = false;

        setTimeout(() => {
            this.scrollTo(this.introPosition + 1, this.duration);

            this.store.toggleListeners('on');
        }, 1000);
    };

    getEasing = (time: number, b: number, c: number, d: number): number => {
        const t = time / d - 1;

        // easeInQuint
        // return c * t * t * t * t * t + b;

        // easeInOutQuad
        // return c * (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t) + b;

        // linear
        // return c * (t / d) + b;

        // easeOutCubic;
        return c * (t * t * t + 1) + b;
    };

    scrollTo = (to: number, duration?: number) => {
        const currentDuration = duration || this.duration;

        const start = window.scrollY;

        const change = to - start;

        const increment = 10;

        let currentTime = 0;

        const animateScroll = () => {
            currentTime += increment;

            let value = Math.trunc(this.getEasing(currentTime, start, change, currentDuration));

            if (this.introWasPlayed && value < this.introPosition) {
                value = this.introPosition;
            }

            if (!this.introWasPlayed && value > this.introPosition) {
                this.introWasPlayed = true;
            }

            window.scrollTo(0, value);

            this.store.framesStore.setCurrentFrame();

            if (currentTime < currentDuration) {
                window.requestAnimationFrame(animateScroll);
            } else {
                this.store.framesStore.setCurrentFrame();
            }
        };

        animateScroll();
    };

    setSegmentIndex = (index: number) => {
        this.segmentIndex = index;
    };
}
