import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

const title = 'Условия обработки персональных данных';
const text =
    '<p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой\n' +
    ' для управления инвестициями и предлагает структурированные продукты с защитой капитала,\n' +
    ' индивидуальные счета доверительного управления, паевые инвестиционные фонды,\n' +
    '            еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и\n' +
    ' внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе,\n' +
    ' работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего\n' +
    ' персонального менеджера.</p><p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой\n' +
    ' для управления инвестициями и предлагает структурированные продукты с защитой капитала,\n' +
    ' индивидуальные счета доверительного управления, паевые инвестиционные фонды,\n' +
    '            еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и\n' +
    ' внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе,\n' +
    ' работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего\n' +
    ' персонального менеджера.</p><p>Почта Банк Private Banking* располагает всеми необходимыми ресурсами и инфраструктурой\n' +
    ' для управления инвестициями и предлагает структурированные продукты с защитой капитала,\n' +
    ' индивидуальные счета доверительного управления, паевые инвестиционные фонды,\n' +
    '            еврооблигации.</p><p>Почта Банк также предлагает своим клиентам брокерское обслуживание на биржевом и\n' +
    ' внебиржевом рынках ценных бумаг. Для клиентов, которым требуется обслуживание в Европе,\n' +
    ' работают дочерние банки в Люксембурге и Цюрихе.</p><p>Более подробную информацию по инвестиционным решениям Вы можете получить у Вашего\n' +
    ' персонального менеджера.</p>';

const PersonalData: React.FC = () => (
    <div className={clsx(cn.wrap, cn.active)}>
        <div className={cn.grid}>
            <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />
            <div className={cn.text} dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    </div>
);

export default PersonalData;
